import axios from "axios"

const initializeApp = () => {

    // Setting base URL for all API request via axios
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

    const theme = localStorage.getItem("theme")

    if (!theme) {
        localStorage.setItem("theme", "light")
    }
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code
        console.log("Running in development mode");
    } else {
        // Prod build code
        console.log("Running in production mode");
        // Removing console.log from prod
        console.log = () => {
        };


        // init analytics here
    }

}

export default initializeApp
