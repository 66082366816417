export const REQUEST_ADD_EVENT = 'REQUEST_ADD_EVENT'
export const REQUEST_ADD_EVENT_SUCCESS = 'REQUEST_ADD_EVENT_SUCCESS'
export const REQUEST_ADD_EVENT_FAILURE = 'REQUEST_ADD_EVENT_FAILURE'

export const REQUEST_GET_EVENT_LIST = 'REQUEST_GET_EVENT_LIST'
export const REQUEST_GET_EVENT_LIST_SUCCESS = 'REQUEST_GET_EVENT_LIST_SUCCESS'
export const REQUEST_GET_EVENT_LIST_FAILURE = 'REQUEST_GET_EVENT_LIST_FAILURE'

export const REQUEST_DELETE_EVENT = 'REQUEST_DELETE_EVENT'
export const REQUEST_DELETE_EVENT_SUCCESS = 'REQUEST_DELETE_EVENT_SUCCESS'
export const REQUEST_DELETE_EVENT_FAILURE = 'REQUEST_DELETE_EVENT_FAILURE'

export const REQUEST_DETAIL_EVENT = 'REQUEST_DETAIL_EVENT'
export const REQUEST_DETAIL_EVENT_SUCCESS = 'REQUEST_DETAIL_EVENT_SUCCESS'
export const REQUEST_DETAIL_EVENT_FAILURE = 'REQUEST_DETAIL_EVENT_FAILURE'

export const REQUEST_UPDATE_EVENT = 'REQUEST_UPDATE_EVENT'
export const REQUEST_UPDATE_EVENT_SUCCESS = 'REQUEST_UPDATE_EVENT_SUCCESS'
export const REQUEST_UPDATE_EVENT_FAILURE = 'REQUEST_UPDATE_EVENT_FAILURE'

export const REQUEST_REGISTRATION_EVENT = 'REQUEST_REGISTRATION_EVENT'
export const REQUEST_REGISTRATION_EVENT_SUCCESS = 'REQUEST_REGISTRATION_EVENT_SUCCESS'
export const REQUEST_REGISTRATION_EVENT_FAILURE = 'REQUEST_REGISTRATION_EVENT_FAILURE'

export const REQUEST_EVENT_ACTIVE = 'REQUEST_EVENT_ACTIVE'
export const REQUEST_EVENT_ACTIVE_SUCCESS = 'REQUEST_EVENT_ACTIVE_SUCCESS'
export const REQUEST_EVENT_ACTIVE_FAILED = 'REQUEST_EVENT_ACTIVE_FAILED'

export const REQUEST_CHECK_REGISTRATION_EVENT = 'REQUEST_CHECK_REGISTRATION_EVENT'
export const REQUEST_CHECK_REGISTRATION_EVENT_SUCCESS = 'REQUEST_CHECK_REGISTRATION_EVENT_SUCCESS'
export const REQUEST_CHECK_REGISTRATION_EVENT_FAILED = 'REQUEST_CHECK_REGISTRATION_EVENT_FAILED'

export const REQUEST_REGISTRATION_EVENT_LIST = 'REQUEST_REGISTRATION_EVENT_LIST'
export const REQUEST_REGISTRATION_EVENT_LIST_SUCCESS = 'REQUEST_REGISTRATION_EVENT_LIST_SUCCESS'
export const REQUEST_REGISTRATION_EVENT_LIST_FAILED = 'REQUEST_REGISTRATION_EVENT_LIST_FAILED'
